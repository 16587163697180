<template>
  <div class="hello">
    <img src="@/assets/micro-bg.png" class="img-fill" alt="" />
    <div class="box">
      <div class="logo-box">
        <div class="logo">
          <img src="" alt="" />
        </div>
        <div class="user">
          <div class="name" v-if="hasLogin">{{ userInfo.nickName }}</div>
          <div class="name" v-else @click="toLogin">暂未登录,去登录</div>
          <el-button type="danger" round v-if="hasLogin" @click="loginOut"
            >退出登录</el-button
          >
        </div>
      </div>
      <div class="bot">
        <div class="left-content">
          <div class="top-logo">
            <img src="@/assets/title.png" class="img-fill" alt="" />
          </div>
          <div class="tip">微心愿圆梦活动 期待您来奉献爱心</div>
          <div class="count-box">
            <div class="count-item">
              <div class="item-icon">
                <img src="@/assets/count1.png" class="img-fill" alt="" />
              </div>
              <div class="item-cont">
                <div class="text">未点亮</div>
                <div class="text">{{ count1 }}个</div>
              </div>
            </div>
            <div class="count-item">
              <div class="item-icon">
                <img src="@/assets/count2.png" class="img-fill" alt="" />
              </div>
              <div class="item-cont">
                <div class="text">点亮中</div>
                <div class="text">{{ count2 }}个</div>
              </div>
            </div>
            <div class="count-item">
              <div class="item-icon">
                <img src="@/assets/count3.png" class="img-fill" alt="" />
              </div>
              <div class="item-cont">
                <div class="text">已点亮</div>
                <div class="text">{{ count3 }}个</div>
              </div>
            </div>
            <div class="count-item">
              <div class="item-icon">
                <img src="@/assets/count4.png" class="img-fill" alt="" />
              </div>
              <div class="item-cont">
                <div class="text">满意度</div>
                <div class="text">{{ count4 }}%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-content">
          <div class="right-count-box">
            <img src="@/assets/button.png" class="img-fill" alt="" />
            <div class="right-cont">
              <div class="right-title">我的功能</div>
              <div class="grid">
                <div
                  class="grid-item"
                  v-for="(item, index) in list"
                  :key="index"
                  @click="toDetail(item.id)"
                >
                  <div class="grid-icon">
                    <img :src="$fimg(item.iconUrl)" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">{{ item.title }}</div>
                </div>
                <div class="grid-item" @click="toWishAll">
                  <div class="grid-icon">
                    <img src="@/assets/xinyuan.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">心愿池</div>
                </div>
                <div class="grid-item" @click="toMypublish(1)">
                  <div class="grid-icon">
                    <img src="@/assets/grid1.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">我发布的心愿</div>
                </div>
                <div class="grid-item" @click="toMypublish(2)">
                  <div class="grid-icon">
                    <img src="@/assets/grid2.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">我认领的心愿</div>
                </div>
                <div class="grid-item" @click="toMypublish(3)">
                  <div class="grid-icon">
                    <img src="@/assets/grid3.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">待评价心愿</div>
                </div>
                <div class="grid-item" @click="toMypublish(4)">
                  <div class="grid-icon">
                    <img src="@/assets/grid4.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">已评价心愿</div>
                </div>
                <div class="grid-item" @click="toEditor">
                  <div class="grid-icon">
                    <img src="@/assets/grid5.png" class="img-fill" alt="" />
                  </div>
                  <div class="grid-text">微心愿简介</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfoByType, findAll, microCount } from "@/api/home"
import { getUserInfo } from "@/api/user"
export default {
  name: "index",
  props: {},
  data() {
    return {
      list: [],
      count1: "",
      count2: "",
      count3: "",
      count4: "",
      userInfo: {},
      hasLogin: false,
    }
  },
  mounted() {
    this.getData()
    if (localStorage.getItem("token")) {
      this.hasLogin = true
      localStorage.setItem("hasLogin", true)
      this.getUserInfoFun()
    }
    this.getCount()
  },
  methods: {
    loginOut() {
      localStorage.clear()
    },
    toLogin() {
      this.$router.push("/login")
    },
    getCount() {
      microCount(4).then((res) => {
        this.count1 = res.data.serverContent
      })
      microCount(5).then((res) => {
        this.count2 = res.data.serverContent
      })
      microCount(6).then((res) => {
        this.count3 = res.data.serverContent
      })
      microCount(7).then((res) => {
        this.count4 = res.data.serverContent
      })
    },
    getUserInfoFun() {
      getUserInfo().then((res) => {
        this.userInfo = res.data
      })
    },
    toEditor() {
      this.$router.push("/editor")
    },
    getData() {
      findAll().then((res) => {
        this.list = res.data
      })
    },
    toDetail(id) {
      this.$router.push({
        path: "/editor",
        query: {
          id,
          type: 1,
        },
      })
    },
    toWishAll() {
      this.$router.push({
        path: "/micro",
        query: {
          type: "publish",
        },
      })
    },
    toMypublish(type) {
      this.$router.push({
        path: "/mypublish",
        query: {
          type,
        },
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  padding: 0;
  margin: 0;
  position: relative;
}
.box {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.logo-box {
  padding: 0 30px;
  height: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.header {
  width: 60px;
  height: 60px;
}
.name {
  color: #fff;
  font-size: 20px;
  margin-right: 50px;
}
.tip {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.count-box {
  width: 80%;
  height: 69px;
  background-color: #fb8031;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.count-item {
  flex: 0 1 25%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-icon {
  width: 50px;
  height: 50px;
  justify-self: center;
}
.iten-cont {
  color: #fff;
  text-align: center;
  justify-self: center;
}
.text {
  color: #fff;
  text-align: center;
}
.bot {
  width: 100vw;
  flex: 1;
  display: flex;
}
.left-content {
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.right-content {
  flex: 0 1 50%;
}
.right-count-box {
  width: 90%;
  height: 92%;
  margin: auto;
  position: relative;
}
.right-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 40px;
}
.right-title {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.top-logo {
  width: 621px;
  height: 250px;
  margin: 0 auto;
}
.grid {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
.grid-item {
  margin: 15px 0;
}
.grid-icon {
  width: 70px;
  height: 70px;
  margin: 10px auto;
}
.grid-text {
  text-align: center;
}
</style>

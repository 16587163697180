import Vue from "vue"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import { fimg } from "@/utils/util"

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$fimg = fimg

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app")

import request from '@/utils/request';
//获取验证码
export function getCode() {
    return request({
        url: '/list',
        method: 'get',
        params: query
    });
}
// 注册
export function register(params) {
    return request({
        url: '/user/registerUser',
        method: 'post',
        data: params
    });
}

//登录
export function login(params) {
    return request({
        url: '/user/registerUserLogin',
        method: 'post',
        data: params
    })
}

// 查询用户信息
export function getUserInfo() {
    return request({
        url: '/user/info',
        method: 'get'
    })
}
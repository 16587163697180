import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register.vue"),
  },
  {
    path: "/micro",
    name: "Micro",
    component: () => import("../views/micro.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import("../views/detail.vue"),
  },
  {
    path: "/publish",
    name: "Publish",
    component: () => import("../views/publish.vue"),
  },
  {
    path: "/complate",
    name: "Complate",
    component: () => import("../views/complate.vue"),
  },
  {
    path: "/mypublish",
    name: "Mypublish",
    component: () => import("../views/myPublish.vue"),
  },
  {
    path: "/editor",
    name: "Editor",
    component: () => import("../views/editor.vue"),
  },
  {
    path: "/comment",
    name: "Comment",
    component: () => import("../views/comment.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router

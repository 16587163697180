import request from '@/utils/request';
// 查询图片
export function getInfoByType(type) {
    return request({
        url: `/images/findType/${type}`,
        method: 'get'
    })
}
export function microDesc() {
    return request({
        url: '/server/findType/1',
        method: 'get'
    })
}
//根据社区查询富文本
export function microEdithor(id) {
    return request({
        url: `/content/findById/${id}`,
        method: 'get'
    })
}
//查询所有社区
export function findAll() {
    return request({
        url: `/content/listAll`,
        method: 'get',
        config: {
            noToken: true
        }
    })
}
//查询首页心愿统计
export function microCount(type) {
    return request({
        url: `/server/findType/${type}`,
        method: 'get',
        config: {
            noToken: true
        }
    })
}
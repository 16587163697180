import axios from "axios"
import { Message } from "element-ui"
import { baseUrl } from "@/utils/config"
import router from "@/router"
// 创建axios实例
const service = axios.create({
  baseURL: baseUrl, // api的base_url
  timeout: 5000, // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 可以在这里添加请求头等信息
    if (config.config && config.config.noToken) {
    } else if (localStorage.getItem("token")) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token")
    }
    return config
  },
  (error) => {
    // 请求错误处理
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    console.log(response.data.code, 1)
    if (response.data.code == 200) {
      // 对响应数据做处理，例如只返回data部分
      const res = response.data
      // 如果有错误码，则进行错误处理
      return res
    } else if (response.data.code == 1011008) {
      Message.error(response.data.message)
      localStorage.clear()
      setTimeout(() => {
        router.push("/")
      }, 800)
    } else if (response.data.code == 1011009) {
      Message.error("请先登录")
      localStorage.clear()
      setTimeout(() => {
        router.push("/login")
      }, 800)
    } else {
      Message.error(response.data.message)
    }
  },
  (error) => {
    // 响应错误处理
    console.log("err" + error) // for debug
    // Message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
